<template>
  <PaymentMethod
    v-show="isEquipInCart"
    name="affirm_method"
    :active="active"
    @onSelected="$emit('onSelected')">
    <div slot="header" class="affirm-button-container">
      <AffirmLogo class="affirm-logo" color="indigo"/>
    </div>
    <AsyncButton
      secondary
      class="async-button"
      :state="step1_state"
      @click.native="sendLink()">
      Send Link
    </AsyncButton>
    <p>Checkout Id: {{ affirmCheckoutToken }}</p>
    <p style="font-size: 12px;">
      {{ affirmUrl }}
    </p>
    <AsyncButton
      secondary
      class="async-button"
      :state="step2_state"
      @click.native="confirmCheckoutLoan()">
      Refresh Status
    </AsyncButton>
    <p>Checkout Status: {{ loanstatus }}</p>
    <p>Affirm Token: {{ affirmToken }}</p>
    <p
      class="affirm-as-low-as"
      data-page-type="cart"
      data-affirm-color="black"
      :data-amount="summary_total" />
    <div v-show="isSubsInCart">
      <h3 class="pm-title">
        Enter your card information
      </h3>
      <p class="pm-text">
        Required for processing your FightCamp subscription.
      </p>
      <p />
      <form id="payment-form">
        <div
          class="checkout_form-creditcard-container"
          :class="{ checkout_disabled: disabled }">
          <div class="checkout_form-creditcard-elements">
            <div class="form-row">
              <div id="card-element" />
              <div id="card-errors" role="alert" />
            </div>
          </div>
        </div>
      </form>
    </div>
    <p>
      You will be redirected to Affirm to securely complete your purchase. It's
      quick and easy—get a real-time decision!
    </p>
    <div v-if="cardInfoError" class="checkout_error">
      <p>{{ cardInfoError }}</p>
    </div>
    <h3 class="terms">
      By completing my purchase, I confirm that I have read and agree to the
      FightCamp
      <a href="/pages/legal-terms" target="_blank">Terms of Service</a> and
      <a href="/pages/privacy-policy" target="_blank"> Privacy Policy </a>, that
      I understand that my membership will automatically renew on a monthly
      basis at the current subscription rate until I pause or cancel.
    </h3>

    <div class="btn-row">
      <a class="change-method" @click="$emit('onSelected')">
        Change Payment Method</a>
      <div
        v-if="disabled || processingTotalCost || !affirmToken"
        class="checkout_processing">
        <div class="checkout_processing_item">
          <font-awesome-icon :icon="['fas', 'circle-notch']" spin />
        </div>
        <h4 class="checkout_processing_item">
          PROCESSING...
        </h4>
      </div>
      <ButtonAction
        v-else
        primary
        class="logo-button"
        @click.native="$emit('onSubmit', validatePayment)">
        Buy with
        <AffirmLogo class="logo-affirm" color="white"/>
      </ButtonAction>
    </div>
    <FullScreenSpinner :open="processingPaymentMethod" />
  </PaymentMethod>
</template>

<script>
  import { mapGetters } from 'vuex';
  import AsyncButton from '@/shared/components/ButtonAsyncState.vue';
  import PaymentMethod from './Checkout_PaymentMethod.vue';
  import paymentHelper from './paymentHelper';
  import FullScreenSpinner from '@/shared/components/SpinnerFullScreen.vue';
  import ButtonAction from '@/shared/components/ButtonAction.vue';
  import { AffirmLogo } from '@fc/angie-ui';

  export default {
    name: 'Checkout_PaymentMethodAffirmTeleSale',
    components: {
      PaymentMethod,
      FullScreenSpinner,
      AsyncButton,
      ButtonAction,
      AffirmLogo,
    },
    props: {
      active: Boolean,
    },
    data() {
      return {
        processingPaymentMethod: false,
        cardInfoError: '',
        loanstatus: '',
        affirmUrl: '',
        step1_state: 0,
        step2_state: 0,
      };
    },
    computed: {
      ...mapGetters([
        'bundle',
        'summary_total',
        'isSubsInCart',
        'isEquipInCart',
        'processingTotalCost',
        'affirmToken',
        'affirmCheckoutToken',
        'order',
        'isShopifyOn'
      ]),
      disabled() {
        return this.processingPaymentMethod;
      },
    },
    watch: {
      processingPaymentMethod(newval, oldval) {
        const body = document.getElementsByTagName('BODY')[0];
        if (this.open) {
          body.style.overflow = 'hidden';
        } else {
          body.style.overflow = 'scroll';
        }
      },
    },
    mounted() {
      const stripePublicKey = process.env.VUE_APP_STRIPE_KEY;
      this.stripe = window.Stripe(stripePublicKey);
      const elements = this.stripe.elements();

      // Custom styling can be passed to options when creating an Element.
      // (Note that this demo uses a wider set of styles than the guide below.)
      const style = {
        base: {
          color: '#32325d',
          fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
          fontSmoothing: 'antialiased',
          fontSize: '16px',
          '::placeholder': {
            color: '#aab7c4',
          },
        },
        invalid: {
          color: '#fa755a',
          iconColor: '#fa755a',
        },
      };

      // Create an instance of the card Element

      this.card = elements.create('card', {
        style,
      });

      // Handle real-time validation errors from the card Element.

      this.card.addEventListener('change', (event) => {
        const displayError = document.getElementById('card-errors');
        if (event.error) {
          displayError.textContent = event.error.message;
        } else {
          displayError.textContent = '';
        }
        this.cardInfoValidated = false;
      });

      this.mounted = false;

      // Add an instance of the card Element into the `card-element` <div
      if (window.affirm && window.affirm.ui) {
        window.affirm.ui.ready(() => {
          window.affirm.ui.refresh();
          window.affirm.ui.error.on('close', () => {
            this.processingPaymentMethod = false;
          });
        });
      }

      if (!this.mounted) {
        this.mounted = true;
        this.card.mount('#card-element');
        // Handle form submission

        const form = document.getElementById('payment-form');
        form.addEventListener('submit', (event) => {
          event.preventDefault();
        });
      }
    },
    methods: {
      toggleAffirm() {
        this.selected_affirm = !this.selected_affirm;
      },
      sendLink() {
        if (this.step1_state === 2) {
          return Promise.resolve();
        }
        const store = this.$store;
        const apiClient = this.$api_client;
        const bundle = this.bundle;
        const affirm = window.affirm;
        const method = 'affirm';
        this.step1_state = 1;
        return paymentHelper
          .createTelesaleCheckoutObject({
            store,
            apiClient,
            affirm,
            bundle,
            method,
          })
          .then(paymentHelper.sendAffirmLink)
          .then((data) => {
            console.log(data);
            this.affirmUrl = data.affirm_redirect_url;
            this.step1_state = 2;

            return paymentHelper
              .confirmCheckoutToken(data.affirm_checkout_id)
              .then((res) => {
                console.log(res);
                this.loanstatus = res.checkout_status;
              });
          })
          .catch((err) => {
            this.step1_state = 3;
          });
      },

      confirmCheckoutLoan() {
        if (this.step2_state === 2) {
          return Promise.resolve();
        }
        this.step2_state = 1;

        return paymentHelper
          .confirmCheckoutToken(this.affirmCheckoutToken)
          .then((res) => {
            console.log(res);
            this.loanstatus = res.checkout_status;
            if (this.loanstatus === 'confirmed') {
              return this.$api_client
                .authorizeAffirm(
                  this.order.orderID,
                  this.affirmCheckoutToken,
                  true,
                )
                .then((token) => {
                  this.step2_state = 2;

                  this.$store.commit('updateAffirmToken', token);
                });
            }
            this.step2_state = 0;
            return {};
          })
          .catch((err) => {
            this.step2_state = 3;
          });
      },
      async validatePayment() {
        this.processingPaymentMethod = true;
        this.cardInfoError = '';
        const card = this.card;
        const stripe = this.stripe;
        const store = this.$store;
        const apiClient = this.$api_client;
        const bundle = this.bundle;
        const affirm = window.affirm;
        const method = 'affirm';

        if (this.isShopifyOn) {
          if (this.isSubsInCart) {
            const paymentMethodResult = await  this.stripe.createPaymentMethod({
              type: 'card',
              card,
            });
            
            if (paymentMethodResult.error) {
              // TODO handle card errors from stripe correctly
              this.processingPaymentMethod = false;
              this.cardInfoError = 'Please check your card details and try again.';
            } else {
              store.dispatch('setPaymentInfo', { paymentMethod: method, paymentMethodObj: paymentMethodResult.paymentMethod }); // maybe pass this instead to confirmOrder?
              await this.$store.dispatch('confirmOrder');
              this.$emit('transactionCompleted');
            }
          }

          await this.$store.dispatch('confirmOrder');
          this.$emit('transactionCompleted');
        } else {

        if (this.isSubsInCart) {
          return paymentHelper
            .createToken({
              stripe,
              card,
              store,
              apiClient,
              affirm,
              bundle,
              method,
            })
            .then(data => paymentHelper
              .createCustomer(data)
              .then(paymentHelper.testCharge)
              .then(paymentHelper.placeOrder)
              .then(() => {
                this.$store.commit('reset');
                this.$emit('transactionCompleted', this.confirmation);
              }))
            .catch((err) => {
              if (err) {
                this.cardInfoError = err.message;
              }
            })
            .then(() => {
              if (this.confirmation) {
                return;
              }
              this.processingPaymentMethod = false;
            });
        }
        return paymentHelper
          .createTelesaleCheckoutObject({
            store,
            apiClient,
            affirm,
            bundle,
            method,
          })
          .then(paymentHelper.placeOrder)
          .then((data) => {
            // this.$analytics.purchase(this.$store.state.confirmation,this.$store.state.order);
            this.$emit('transactionCompleted', this.confirmation);
          })
          .catch((err) => {
            if (err) {
              this.cardInfoError = err.message;
            }
          })
          .then(() => {
            this.processingPaymentMethod = false;
          });
        }
      },
    },
  };
</script>

<style>
.tooltip {
  display: block !important;
  z-index: 10000;
}

.async-button {
  margin: 10px 0;
  max-width: 200px;
}

.tooltip .tooltip-inner {
  background: white;
  display: flex;
  flex-direction: row;
  border-radius: 16px;
  padding: 10px 10px 10px;
  text-align: center;
  max-width: 250px;
  margin: 0 auto;
  font-size: 14px;
  line-height: 1.25;
}

.tooltip .tooltip-arrow {
  width: 0;
  height: 0;
  border-style: solid;
  position: absolute;
  margin: 5px;
  z-index: 1;
  color: white;
}

.tooltip[x-placement^="top"] {
  margin-bottom: 10px;
}

.tooltip[x-placement^="top"] .tooltip-arrow {
  border-width: 5px 5px 0 5px;
  border-left-color: transparent !important;
  border-right-color: transparent !important;
  border-bottom-color: transparent !important;
  bottom: -5px;
  left: calc(50% - 5px);
  margin-top: 0;
  margin-bottom: 0;
}

.tooltip[x-placement^="bottom"] {
  margin-top: 5px;
}

.tooltip[x-placement^="bottom"] .tooltip-arrow {
  border-width: 0 5px 5px 5px;
  border-left-color: transparent !important;
  border-right-color: transparent !important;
  border-top-color: transparent !important;
  top: -5px;
  left: calc(50% - 5px);
  margin-top: 0;
  margin-bottom: 0;
}

.tooltip[x-placement^="right"] {
  margin-left: 5px;
}

.tooltip[x-placement^="right"] .tooltip-arrow {
  border-width: 5px 5px 5px 0;
  border-left-color: transparent !important;
  border-top-color: transparent !important;
  border-bottom-color: transparent !important;
  left: -5px;
  top: calc(50% - 5px);
  margin-left: 0;
  margin-right: 0;
}

.tooltip[x-placement^="left"] {
  margin-right: 5px;
}

.tooltip[x-placement^="left"] .tooltip-arrow {
  border-width: 5px 0 5px 5px;
  border-top-color: transparent !important;
  border-right-color: transparent !important;
  border-bottom-color: transparent !important;
  right: -5px;
  top: calc(50% - 5px);
  margin-left: 0;
  margin-right: 0;
}

.tooltip.popover .popover-inner {
  background: #f9f9f9;
  color: white;
  padding: 24px;
  border-radius: 5px;
  box-shadow: 0 5px 30px rgba(black, 0.1);
}

.tooltip.popover .popover-arrow {
  border-color: #f9f9f9;
}

.tooltip[aria-hidden="true"] {
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.15s, visibility 0.15s;
}

.tooltip[aria-hidden="false"] {
  visibility: visible;
  opacity: 1;
  transition: opacity 0.15s;
}
</style>
<style scoped lang="scss">

h3 {
  margin: 10px 0;
}

p {
  margin: 5px 0;
}

.checkout_form-creditcard-elements {
  padding: 0 0;
}

/**
 * The CSS shown here will not be introduced in the Quickstart guide, but shows
 * how you can use CSS to style your Element's container.
 */

.StripeElement {
  background-color: white;
  padding: 12px 12px;
  border: 1px solid #e4e6e7;
  border-radius: 50px;
}

.StripeElement--focus {
  border-color: #000;
}

.StripeElement--invalid {
  border-color: #fa755a;
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}

#card-errors {
  color: #fa755a;
  font-family: $font-stack-light;
  margin-top: 10px;
}

.checkout_processing {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.checkout_processing_item {
  padding-left: 10px;
}

.affirm-logo {
  height: 42px;
}

.pm-text {
  font-size: 14px;
  padding-right: 5px;
  text-align: center;

  @media #{$tablet} {
    text-align: left;
  }

  @media #{$desktop} {
    text-align: left;
  }
}

.pm-title {
  text-transform: none;
  letter-spacing: 0;
  margin: 20px 0 0;
  text-align: center;

  @media #{$tablet} {
    text-align: left;
  }

  @media #{$desktop} {
    text-align: left;
  }
}

.terms {
  text-transform: none;
  line-height: 1.5;
  letter-spacing: 0;
  margin: 20px 0 0;
}

.affirm-button-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  max-width: 250px;

  @media #{$tablet} {
    max-width: 300px;
  }

  @media #{$desktop} {
    max-width: 300px;
  }
}

.affirm-button {
  border: 2px solid #e4e6e7;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 3px;
  padding: 2px 10px;
}

.affirm-button-link {
  text-decoration: underline;
  color: #212121;
}

.logo {
  height: 16px;
  margin: 0 1px;
}

.btn-row {
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-between;
  align-items: center;

  @media #{$tablet} {
    flex-direction: row;
  }

  @media #{$desktop} {
    flex-direction: row;
  }
}

.btn {
  width: 100%;
  text-align: center;
  font-family: $font-stack-bold;
  margin: 20px 0;

  @media #{$tablet} {
    width: auto;
  }

  @media #{$desktop} {
    width: auto;
  }

  display: flex;
  flex-direction: row;
  flex: 0 1 auto;
  justify-content: center;
  align-items: center;
  padding: 16px 20px 8px 20px;
}

.btn2 {
  display: flex;
  flex-direction: row;
  flex: 0 1 auto;
  justify-content: center;
  align-items: center;
  padding: 16px 20px 16px 20px;
}

.logo-affirm {
  height: 30px;
  fill: white;
  margin-top: -6px;
  margin-bottom: 10px;
  margin-left: 6px;
}

.logo-button {
  margin: 10px;
  padding: 16px 20px 4px 20px;
}

.btn p {
  margin: 0;
  flex: 0 0 auto;
}

.affirm-as-low-as {
  max-width: 300px;
  text-align: center;
  font-size: 18px;
  padding: 10px;
  margin: 20px auto;

  @media #{$tablet} {
    max-width: 100%;
  }

  @media #{$desktop} {
    max-width: 100%;
  }
}

.checkout_error {
  color: $color-accent;
}

.change-method {
  text-decoration: underline;
}
</style>
