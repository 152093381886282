import axios from 'axios';
import { useAnalytics } from '@fc/app-common';
import { customerIdIdentified } from '@/shared/scripts/events';
import { SharedFeatureToggleIds } from '../../constants/SharedFeatureToggleIds';
import { FulfillmentType } from '../../constants';
import { isExperimentSelected } from '@fc/app-common';

class PaymentHelperError extends Error {
  constructor(message) {
    super(message);
    this.message = message;
    this.name = 'PaymentHelperError';
  }
}

function errorHandler(err) {
  console.log(err);
  if (err instanceof PaymentHelperError) {
    throw err;
  }
  if (err) {
    throw new PaymentHelperError(err.message);
  }
  throw new PaymentHelperError('Something went wrong. Please verify the info and try again.');
}
function createTokenData(_data) {
  const data = _data;
  if (data.store.state.checkout.shippingAddress === null) {
    throw new PaymentHelperError('Shipping Info needs to be filled');
  }

  data.tokenData = {};
  data.tokenData.name = `${data.store.state.checkout.shippingAddress.firstName} ${data.store.state.checkout.shippingAddress.lastName}`;
  data.tokenData.address_line1 = data.store.state.checkout.shippingAddress.address;
  data.tokenData.address_line2 = data.store.state.checkout.shippingAddress.address2;
  data.tokenData.address_city = data.store.state.checkout.shippingAddress.city;
  data.tokenData.address_state = data.store.state.checkout.shippingAddress.state;
  data.tokenData.address_zip = data.store.state.checkout.shippingAddress.postalCode;
  data.tokenData.address_country = data.store.state.checkout.shippingAddress.country;
  return data;
}

function createStripeToken(_data) {
  const data = _data;

  return data.stripe.createToken(data.card, data.tokenData)
    .then((result) => {
      if (result.error) {
        throw new PaymentHelperError(result.error.message);
      }
      if (result.token.card.funding === 'prepaid') {
        throw new PaymentHelperError('We do not accept prepaid cards. Please use a different card.');
      }
      data.stripeToken = result.token;
      return data;
    });
}

function subscription(subId) {
  return ['fightcampplanAffirm', 'prepay1year', 'prepay2years', 'incentive1year', 'incentive2years'].includes(subId);
}

function getFulfillmentType({ getters }) {
  const packageRestructureToggle = !!getters.getExperimentById
    && getters.getExperimentById(SharedFeatureToggleIds.ENABLE_PACKAGE_RESTRUCTURE);
  const packageRestructureEnabled = packageRestructureToggle
    ? packageRestructureToggle.variant === '1'
    : isExperimentSelected(SharedFeatureToggleIds.ENABLE_PACKAGE_RESTRUCTURE);
  let fulfillmentType = FulfillmentType.SINGLE_ITEM_PACKAGE;

  if (packageRestructureEnabled) {
    fulfillmentType = FulfillmentType.MULTI_ITEM_PACKAGE;
  }

  return fulfillmentType;
}

export default {
  async addStripeToken(token, data) {
    const updatedData = createTokenData(data);
    updatedData.stripeToken = token;

    if (token.card) {
      updatedData.card = token.card;
    }

    return updatedData;
  },
  createToken(_data) {
    return Promise.resolve(_data)
      .then(createTokenData)
      .then(createStripeToken)
      .catch(errorHandler);
  },
  createCustomer(_data) {
    return Promise.resolve(_data)
      .then((data) => {
        const stripeToken = data.stripeToken ? data.stripeToken.id : null;
        const { checkout } = data.store.state;

        return data.apiClient.createCustomer(
          stripeToken,
          checkout.shippingAddress.email,
          checkout.shippingAddress,
        ).then(({ id, uid }) => {
          data.store.commit('updateCustomerId', id);
          const updatedData = data;

          updatedData.customerId = id;
          if (uid) {
            const analytics = useAnalytics();
            updatedData.uid = uid.split('|')[1];
            analytics.UIDIdentified(updatedData.uid);
          }

          return updatedData;
        });
      })
      .catch(errorHandler);
  },

  placeOrder(_data) {
    return Promise.resolve(_data)
      .then(data => data.apiClient
        .placeOrder({
          orderId: data.store.state.checkout.order.orderID,
          productList: data.store.state.checkout.cart,
          customerId: data.customerId,
          email: data.store.state.checkout.shippingAddress.email,
          shipInfo: data.store.state.checkout.shippingAddress,
          costBreakdown: data.store.state.checkout.order.costBreakdown,
          affirmLoanId: data.store.state.checkout.affirmToken,
          uid: data.uid,
          fulfillmentType: getFulfillmentType(data.store),
          options: {
            paymentMethod: data.method,
            insideSale: data.store.state.app ? data.store.state.app.representative : null,
            retail: data.store.state.app ? data.store.state.app.location : null,
          },
          newCustomer: data.store.state.checkout.new_customer,
        })
        .then((order) => {
          data.store.commit('setConfirmation', {
            email: data.store.state.checkout.shippingAddress.email,
            textPhoneNumber: data.store.state.checkout.shippingAddress.phoneNumber,
            orderNumber: order.orderName,
            orderID: order.orderID,
            order: data.store.state.checkout.order,
            method: data.method,
            cart: data.store.state.checkout.cart,
            isNewCustomer: data.store.state.checkout.new_customer,
            uid: data.uid,
            serverExperiments: order.experiments,
            name: `${data.store.state.checkout.shippingAddress.firstName} ${data.store.state.checkout.shippingAddress.lastName}`,
          });
          data.store.commit('updateStripeToken', null);
        }))
      .catch(errorHandler);
  },

  createAffirmCheckoutObject(_data) {
    return Promise.resolve(_data).then((data) => {
      if ((!data.store.getters.isShopifyOn && data.store.state.checkout.shippingAddress === null) || data.store.state.checkout.order === null) {
        throw new PaymentHelperError('Shipping Info needs to be filled');
      }
      const { shippingAddress, order, cart, shipping, checkoutEmail } = data.store.state.checkout;

      const checkoutObject = {
        merchant: {
          user_confirmation_url: process.env.VUE_APP_AFFIRM_URL_CONFIRMATION_PAGE,
          user_cancel_url: process.env.VUE_APP_AFFIRM_URL_CANCEL_PAGE,
          user_confirmation_url_action: 'POST',
          name: 'FightCamp',
        },
        shipping: {
          name: {
            first: shippingAddress?.firstName || shipping.firstName,
            last: shippingAddress?.lastName || shipping.lastName,
          },
          address: {
            line1: shippingAddress?.address || shipping.address,
            line2: shippingAddress?.address2 || shipping.address2,
            city: shippingAddress?.city || shipping.city,
            state: shippingAddress?.state || shipping.state,
            zipcode: shippingAddress?.postalCode || shipping.postalCode,
            country: shippingAddress?.country || shipping.country,
          },
          phone_number: shippingAddress?.phoneNumber || shipping.phoneNumber,
          email: shippingAddress?.email || checkoutEmail,
        },
        items: [],
        metadata: {
          placeOrder: {
            cart,
            order,
            customerID: data.customerId,
            ...(shippingAddress ? { shippingAddress } : { ...shipping, email: checkoutEmail }),
            options: {
              paymentMethod: data.method,
              insideSale: data.store.state.app ? data.store.state.app.representative : null,
              retail: data.store.state.app ? data.store.state.app.location : null,
            },
          },
          mode: 'modal',
        },
        shipping_amount: order.costBreakdown.productShipping,
        tax_amount: order.costBreakdown.productTax,
        total: order.costBreakdown.totalCost,
      };

      if (!data.store.getters.isShopifyOn) {
        data.bundle.forEach((item) => {
          if (item.type === 'equip' || subscription(item.subid)) {
            checkoutObject.items.push({
              display_name: item.productName,
              sku: item.id,
              unit_price: item.price,
              qty: item.qty,
              item_url: 'https://app.joinfightcamp.com/shop/',
              item_image_url: 'https://cdn.joinfightcamp.com/images/website-v3/equipments/fightcamp-homekit-white-bag-gloves-mat-002.png',
            });
          }
        });
      } else {
        data.cartProducts.shopifyItems.forEach((item) => {
          // subscription items do not seem to be passed in the affirm checkout object API logs so only passing items
          checkoutObject.items.push({
            display_name: item.title,
            sku: item.variant.id,
            unit_price: item.variant.price,
            qty: cart.find(cartItem => cartItem.id === item.variant.id)?.qty,
            item_url: 'https://app.joinfightcamp.com/shop/',
            item_image_url: item?.image || 'https://cdn.joinfightcamp.com/images/website-v3/equipments/fightcamp-homekit-white-bag-gloves-mat-002.png',
          });
        });
      }

      const updatedData = data;
      updatedData.checkout_object = checkoutObject;
      return updatedData;
    });
  },


  createTelesaleCheckoutObject(_data) {
    return Promise.resolve().then(() => {
      const data = _data;

      let shippingData;
      const { shippingAddress, order } = data.store.state.checkout;
      const { shipping } = data.store.getters;

      shippingData = shippingAddress || shipping;
      if (shippingData === null || order === null) {
        throw new PaymentHelperError('Shipping Info needs to be filled');
      }

      const checkoutObject = {
        merchant: {
          public_api_key: process.env.VUE_APP_AFFIRM_PUBLIC_KEY,
          user_confirmation_url: 'https://joinfightcamp.com/financing',
          user_cancel_url: 'https://joinfightcamp.com/financing',
          user_confirmation_url_action: 'POST',
          name: 'FightCamp',
        },
        billing: {
          name: {
            first: shippingData.firstName,
            last: shippingData.lastName,
          },
          address: {
            line1: shippingData.address,
            line2: shippingData.address2,
            city: shippingData.city,
            state: shippingData.state,
            zipcode: shippingData.postalCode,
            country: shippingData.country,
          },
          phone_number: shippingData.phoneNumber,
          email: shippingData.email,
        },
        shipping: {
          name: {
            first: shippingData.firstName,
            last: shippingData.lastName,
          },
          address: {
            line1: shippingData.address,
            line2: shippingData.address2,
            city: shippingData.city,
            state: shippingData.state,
            zipcode: shippingData.postalCode,
            country: shippingData.country,
          },
          phone_number: shippingData.phoneNumber,
          email: shippingData.email,
        },
        items: [],
        metadata: {
          mode: 'modal',
        },
        shipping_amount: order.costBreakdown.productShipping,
        tax_amount: order.costBreakdown.productTax,
        total: order.costBreakdown.totalCost,
      };
      data.bundle.forEach((item) => {
        if (item.type === 'equip' || subscription(item.subid)) {
          checkoutObject.items.push({
            display_name: item.productName,
            sku: item.id,
            unit_price: item.price,
            qty: item.qty,
            item_url: 'https://app.joinfightcamp.com/shop/',
            item_image_url: 'https://cdn.joinfightcamp.com/images/website-v3/equipments/fightcamp-homekit-white-bag-gloves-mat-002.png',
          });
        }
      });

      const updatedData = data;
      updatedData.checkout_object = checkoutObject;
      return updatedData;
    });
  },

  launchAffirm(_data) {
    const data = _data;

    if (data.checkout_object) {
      return new Promise((resolve, reject) => {
        data.affirm.checkout(data.checkout_object);
        data.affirm.checkout.open({
          onFail: (a) => {
            reject();
          },
          onSuccess: (result) => {
            data.affirm_checkout_token = result.checkout_token;
            resolve(data);
          },
        });
      })
        .then(() => data.apiClient.authorizeAffirm(data.store.state.checkout.order.orderID, data.affirm_checkout_token))
        .then((token) => {
          data.store.commit('updateAffirmToken', token);
          return data;
        })
        .catch(errorHandler);
    }
    return data;
  },


  sendAffirmLink(_data) {
    return axios.post(`${process.env.VUE_APP_AFFIRM_HOST}/api/v2/checkout/store`, _data.checkout_object)
      .then((result) => {
        const data = _data;
        data.affirm_checkout_id = result.data.checkout_id;
        data.affirm_redirect_url = result.data.redirect_url;

        data.store.commit('updateAffirmCheckoutToken', data.affirm_checkout_id);
        return data;
      });
  },

  confirmCheckoutToken(token) {
    return axios.post(`${process.env.VUE_APP_API_URL}/statusAffirm/${token}`, {})
      .then(result => result.data);
  },


  testCharge(_data) {
    if (_data.store.getters.isSubsInCart) {
      return Promise.resolve(_data)
        .then(data => data.apiClient
          .testChargePreAffirm(
            data.customerId,
            data.store.state.checkout.shippingAddress.email,
          ).then(() => data))
        .catch(errorHandler);
    }
    return _data;
  },

};
